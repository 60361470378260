<template>
  <div class="d-flex flex-column flex-lg-row flex-column-fluid">
    <div
      class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1"
    >
      <div class="d-flex flex-center flex-column flex-lg-row-fluid">
        <div class="w-lg-500px p-10">
          <router-view></router-view>
        </div>
      </div>
      <div
        class="w-lg-500px px-3 mx-auto"
        :class="{
          'd-flex flex-stack': !$isMobile(),
          'd-table text-center': $isMobile(),
        }"
      >
        <div :class="{ 'me-10': !$isMobile(), 'me-2 mb-3': $isMobile() }">
          <div class="btn-group dropup">
            <button
              type="button"
              class="btn btn-secondary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                class="w-15px h-15px rounded-1 me-1"
                :src="`${require('@/assets/img/flags/' +
                  currentLangugeLocale.flag)}`"
                :alt="currentLangugeLocale.name"
              />
              {{ currentLangugeLocale.name }}
            </button>
            <ul class="dropdown-menu">
              <li
                v-for="item in locales.filter((item) => {
                  return item.code !== activeLanguage;
                })"
                class="cursor-pointer"
                :key="item.code"
              >
                <a
                  @click="setLang(item.code)"
                  class="dropdown-item"
                  :class="{ active: activeLanguage == item.code }"
                >
                  <span class="symbol symbol-20px me-2">
                    <img
                      class="rounded-1"
                      :src="`${require('@/assets/img/flags/' +
                        item.code +
                        '.svg')}`"
                    />
                  </span>
                  <span class="fs-6">
                    {{
                      $t(
                        "Language." + item.code,
                        {},
                        { locale: activeLanguage }
                      )
                    }}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          v-html="$config.localization[activeLanguage].auth.quickLinks"
        ></div>
      </div>
    </div>
    <div
      class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 bgi-attachment-fixed"
      :class="this.$route.name"
      :style="
        this.$route.meta.backgroundImage
          ? {
              'background-image': String.format(
                'url({0})',
                this.$route.meta.backgroundImage
              ),
            }
          : {}
      "
    >
      <template
        v-if="
          !this.$route.meta.isPasswordResetPage &&
          !this.$route.meta.isPasswordChangePage &&
          !this.$route.meta.isSignUpPage
        "
      >
        <div
          class="w-100"
          v-html="$config.localization[activeLanguage].auth.introduction"
        ></div>
      </template>
    </div>
  </div>
</template>
<script>
import { getSupportedLocales } from "@/core/i18n/supported-locales";
import { computed } from "vue";
import { useI18n } from "vue-i18n/index";
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { removeModals, removeModalBackdrops } from "@/core/helpers/dom";

export default {
  name: "Auth",
  data() {
    return {
      locales: getSupportedLocales(),
      countries: [
        {
          flag: "uk.svg",
          name: "English",
          code: "en",
        },
        {
          flag: "tr.svg",
          name: "Türkçe",
          code: "tr",
        },
      ],
      currentLangugeLocale: {},
      activeLanguage:
        localStorage.getItem("activeLang") || this.$store.state.activeLang,
    };
  },
  watch: {
    "$store.state.activeLang"() {
      this.activeLanguage = this.$store.state.activeLang;
    },
  },
  methods: {
    setLang(code) {
      this.currentLangugeLocale = this.countries.find((f) => f.code == code);
      this.$root.setCurrentUserLanguage(code, true);
    },
  },
  created() {
    this.currentLangugeLocale = this.countries.find(
      (f) => f.code == this.activeLanguage
    );
  },
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      // store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");

      removeModals();
      removeModalBackdrops();
    });

    onUnmounted(() => {
      // store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
  },
};
</script>
